<template>
  <div :id="id" :class="{ 'expansion-panel--disabled': disabled }" class="expansion-panel">
    <div
      :class="{
        'expansion-panel__header--disabled': disabled,
        'expansion-panel__header--always-open': alwaysOpen,
        'cursor-pointer': !alwaysOpen && !disabled,
      }"
      :style="{ padding: headerPadding }"
      class="expansion-panel__header"
      @click="disabled || alwaysOpen ? null : handlePanelOpened()"
    >
      <div
        v-if="isExpandIconVisible"
        :class="{ 'order-3': arrowOptions?.side === 'right' }"
        class="expansion-panel__header__block"
      >
        <Icon
          :style="{
            transform: `rotate(${arrowRotation}deg)`,
            color: expandIconColor,
          }"
          class="expand-icon transition-all"
          name="eva:arrow-ios-forward-outline"
          size="20"
        ></Icon>
      </div>

      <div class="flex-grow">
        <slot name="title" />
      </div>

      <div v-if="!noActions" class="expansion-panel__header__block shrink-0">
        <slot name="actions" />
      </div>
    </div>

    <transition @enter="enter" @leave="leave" @before-enter="beforeEnter" @before-leave="beforeLeave">
      <div
        v-if="isOpen"
        :class="{ 'expansion-panel__content--open': isOpen }"
        :style="{ height: isOpen ? `${contentHeight}px` : 0 }"
        class="expansion-panel__content transition-all"
      >
        <div ref="contentEl">
          <slot name="content" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts" setup>
import { ExpansionPanelConfig } from '~/shared/ui/expansion-panel/expansion-panel.inventory';

interface ArrowOptions {
  secondary?: boolean;
  side?: 'right' | 'left';
  direction?: 'horizontal' | 'vertical';
}

const props = withDefaults(
  defineProps<{
    headerPadding?: string;
    disabled?: boolean;
    arrowOptions?: ArrowOptions;
    noActions?: boolean;
    initialOpen?: boolean;
    alwaysOpen?: boolean;
    id?: string;
  }>(),
  {
    disabled: false,
    headerPadding: '32px',
    arrowOptions: () => ({}),
    noActions: false,
    initialOpen: false,
    alwaysOpen: false,
    id: '',
  }
);

const emit = defineEmits<{
  (e: 'opened'): void;
}>();

const app = useNuxtApp();
const contentEl = ref(null);
const contentHeight = ref(0);
const isOpen = ref(false);

useResizeObserver(contentEl, entries => {
  const entry = entries[0];
  const { height } = entry.contentRect;
  contentHeight.value = height;
});

const config = computed<ExpansionPanelConfig | undefined>(() => {
  return app.$ui.expansionPanel.getConfiguration(props.id);
});

const expandIconColor = computed<string>(() => {
  const colors = app.$ui.colors;

  if (props.disabled) {
    return colors.disabledText;
  }

  if (props.arrowOptions?.secondary && !isOpen.value) {
    return colors['grey-blue'][100];
  }

  return colors.blue[100];
});

const isExpandIconVisible = computed<boolean>(() => {
  return !props.alwaysOpen;
});

const arrowRotation = computed<number>(() => {
  if (props.arrowOptions?.direction === 'horizontal') {
    return isOpen.value ? 270 : 90;
  }

  if (props.arrowOptions.side == 'right') {
    return isOpen.value ? 90 : 180;
  }

  return isOpen.value ? 90 : 0;
});

onMounted(() => {
  if (config.value?.open || props.initialOpen || props.alwaysOpen) {
    isOpen.value = true;
  }

  // if (config?.scroll) {
  //TODO: add native
  // VueScrollTo.scrollTo(this.$el, {
  //     offset: config?.scrollOffset || 0,
  // });
  // }

  if (config.value) {
    app.$ui.expansionPanel.deleteConfiguration(props.id);
  }
});

function handlePanelOpened() {
  if (!isOpen.value) {
    emit('opened');
  }

  isOpen.value = !isOpen.value;
}

function beforeEnter(el: Element) {
  const htmlEl = el as HTMLElement;
  htmlEl.style.height = '0';
}

function enter(el: Element) {
  const htmlEl = el as HTMLElement;
  htmlEl.style.height = el.scrollHeight + 'px';
}

function beforeLeave(el: Element) {
  const htmlEl = el as HTMLElement;
  htmlEl.style.height = el.scrollHeight + 'px';
}

function leave(el: Element) {
  const htmlEl = el as HTMLElement;
  htmlEl.style.height = '0';
}
</script>

<style lang="scss">
.expansion-panel {
  background: white;
  border-radius: inherit;

  &--disabled {
    background: theme('colors.grey.4');
    color: theme('colors.grey.32');
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__block {
      display: flex;
      align-items: center;
    }
  }

  &__content {
    padding-bottom: 0;
    overflow: clip;
    height: 0;
    opacity: 0;

    &--open {
      opacity: 1;
    }
  }

  .expand-icon {
    margin-right: 12px;
  }
}
</style>
